import Time from '~/common/helpers/time/Time'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store/'
import { useAppStateStore } from '~/store/app'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'
import { MapService } from '~/common/services/map/yandex/MapService'
import { MapModule } from '~/common/interfaces/map/yandex/Map.module'
import gridIcon from '~/assets/icons/common/grid.svg'
import filterIcon from '~/assets/icons/actions/additional-filters.svg'
import buildingIcon from '~/assets/icons/entities/building.svg'
import closeIcon from '~/assets/icons/actions/close.svg'
import { ButtonService } from '~/common/services/map/yandex/ButtonService'
import { MapBuildingFeatureObject } from '~/common/types/map/ymaps.buildingFeatureObject'
import { GeometriesCoordinates } from '~/common/types/map/ymaps.geometriesCoordinates'
import { MarketEntitiesActionsHttpApiService } from '~/modules/market-entities-actions/api/MarketEntitiesActionsHttpApiService'
import { MapFeatureDrawerService } from '~/common/services/map/yandex/MapFeatureDrawerService'
import { MapPaint } from '~/common/services/map/yandex/MapPaint'
import { ObjectHelper } from '~/common/helpers/object'
import { EntitiesCount } from '~/common/types/entitiesCount/EntitiesCount'
import { HttpApiService } from '~/services/http/HttpApiService'
import SentryLogger from '~/services/sentry/SentryLogger'

type MapCallbacks = {
  onCloseClick: () => void
  onFilterClick: () => void
  onPointClick?: (id: number) => void
  onMobileShowBuildingClick: () => void
}

export class BuildingSearchMapService extends MapService {
  private debounce: number
  private http: MarketEntitiesActionsHttpApiService
  private marketEntitiesStore
  private appStore
  private callbacks
  private mapPaint: MapPaint | undefined
  private t
  private featureDrawerService: MapFeatureDrawerService | undefined
  private isSetBoundsByFeatureCollection
  private mobileShowBuildingsButton: ymaps.control.Button | undefined

  constructor(
    callbacks: MapCallbacks,
    mapConfig = { state: {}, options: {} },
    modules: Record<string, MapModule> = {},
    isSetBoundsByFeatureCollection = false,
  ) {
    super(mapConfig, modules)

    this.t = useI18n().t
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new MarketEntitiesActionsHttpApiService(httpInstance as HttpApiService)

    this.appStore = useAppStateStore()
    this.marketEntitiesStore = useMarketEntitiesActionsStore()

    this.debounce = 0

    this.callbacks = callbacks
    this.isSetBoundsByFeatureCollection = isSetBoundsByFeatureCollection
  }

  public async createMap(mapId: string): Promise<void> {
    await super.createMap(mapId)
    this.featureDrawerService = new MapFeatureDrawerService(
      this.map,
      {
        clusterize: false,
        clusterOpenBalloonOnClick: false,
        geoObjectOpenBalloonOnClick: false,
      },
      { onFeatureClick: this.callbacks.onPointClick },
      buildingIcon,
    )
    this.changeControlsPosition()

    this.mapPaint = new MapPaint(
      this.map,
      { atTheEndOfDrawing: this.atPaintingEnd, context: this },
      this.t,
      this.marketEntitiesStore.filter.buildingFilter.geometriesCoordinates || [],
      ['id'],
    )

    this.map.controls.add(this.createFilterButton())
    this.map.controls.add(this.createCloseButton())

    this.map.controls.add(this.mapPaint.paintButton)
    this.mapPaint.subscribeToClickOnMap()

    if (this.appStore.breakpoints.isMd) {
      this.map.controls.add(this.createShowGridButton())
    } else {
      this.mobileShowBuildingsButton = this.createShowBuildingsButton()
      this.map.controls.add(this.mobileShowBuildingsButton)
    }

    this.getFeatureByMovingMap()
  }

  private getFeatureByMovingMap(): void {
    this.map.events.add('actionend', () => {
      this.debounce = Time.delayFromLatestEvent(this.debounce, () => {
        this.sendAllRequests()
      }, 300)
    })
    this.map.events.add('sizechange', () => {
      this.debounce = Time.delayFromLatestEvent(this.debounce, () => {
        this.sendAllRequests(1, this.isSetBoundsByFeatureCollection)
        if (this.isSetBoundsByFeatureCollection) {
          this.isSetBoundsByFeatureCollection = false
        }
      }, 300)
    })
  }

  private getCount(): Promise<EntitiesCount> {
    return new Promise((resolve, reject) => {
      this.marketEntitiesStore.isFetchingEntitiesCount = true
      const filter = this.createFilterForRequest()
      this.http.getCount(filter)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить количество жк',
            filter,
          })
          reject(error)
        })
        .finally(() => {
          this.marketEntitiesStore.isFetchingEntitiesCount = false
        })
    })
  }

  private searchBuildings(page = 1): Promise<any> {
    return new Promise((resolve, reject) => {
      const filter = this.createFilterForRequest()
      this.http.searchBuildings(
        filter,
        page,
        'price',
      )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить жк',
            filter,
            page,
          })
          reject(error)
        })
    })
  }

  private getMapFeatureCollection(): Promise<ymaps.IFeatureCollection<MapBuildingFeatureObject>> {
    return new Promise((resolve, reject) => {
      const filter = this.createFilterForRequest(true)
      this.http.searchFeatureCollection(filter, this.getBBox(), this.getZoom())
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Проблема с картой',
            filter,
          })
          reject(error)
        })
    })
  }

  private setCount() {
    this.getCount().then(count => {
      this.marketEntitiesStore.setMapBuildingsCount(count)
      this.mobileShowBuildingsButton?.data.set('content', `${count.buildingsCount}`)
    })
  }

  public setBuildings(page = 1) {
    this.marketEntitiesStore.setIsMapBuildingsFetching(true)
    this.searchBuildings(page).then(data => {
      this.marketEntitiesStore.setMapPagination({
        currentPage: data.currentPage,
        lastPage: data.lastPage,
        perPage: data.perPage,
        total: data.total,
        nextPageUrl: data.nextPageUrl,
      })
      this.marketEntitiesStore.setMapBuildings(data.data)
    }).finally(() => {
      this.marketEntitiesStore.setIsMapBuildingsFetching(false)
    })
  }

  private setFeatureCollection(withSetBounds = false) {
    this.getMapFeatureCollection().then(featureCollection => {
      this.displayPointsOnMap(featureCollection)
      if (withSetBounds) {
        this.setMapBoundsByFeatureCollection(featureCollection)
      }
    })
  }

  private setMapBoundsByFeatureCollection(featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject>) {
    const xBounds: Array<number> = []
    const yBounds: Array<number> = []
    featureCollection.features.forEach(feature => {
      if (feature.features) {
        feature.features.features.forEach(feat => {
          const { coordinates } = feat.geometry as ymaps.GeometryPoint
          xBounds.push(coordinates[0])
          yBounds.push(coordinates[1])
        })
      } else {
        const { coordinates } = feature.geometry as ymaps.GeometryPoint
        xBounds.push(coordinates[0])
        yBounds.push(coordinates[1])
      }
    })
    const xMinBound = Math.min(...xBounds)
    const xMaxBound = Math.max(...xBounds, 0)
    const yMinBound = Math.min(...yBounds)
    const yMaxBound = Math.max(...yBounds, 0)
    if (xMinBound && xMaxBound && yMinBound && yMaxBound) {
      super.setMapBounds([[xMinBound, yMinBound], [xMaxBound, yMaxBound]])
    }
  }

  public sendAllRequests(page = 1, withSetBounds = false) {
    this.setCount()
    this.setBuildings(page)
    this.setFeatureCollection(withSetBounds)
  }

  private createShowGridButton(): ymaps.control.Button {
    return ButtonService.createButton({
      image: gridIcon,
      content: this.i18n.t('map.actions.showGrid'),
      layout: '<button class="hidden md:flex items-center text-primary font-semibold bg-white px-3 lg:px-4 rounded-full button-shadow" style="height: 38px">' +
              '<span class="block w-[14px] h-[14px] md:w-[16px] md:h-[16px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
              '<span class="ml-2 hidden lg:inline">' +
                '{{ data.content }}' +
              '</span>' +
            '</button>',
      floatIndex: 20,
      onClick: this.callbacks.onCloseClick,
    })
  }

  private createShowBuildingsButton(): ymaps.control.Button {
    return ButtonService.createButton({
      image: buildingIcon,
      content: `${this.marketEntitiesStore.mapBuildingsCount.buildingsCount || 0}`,
      layout: '<button class="flex md:hidden items-center text-primary font-semibold bg-white px-4 rounded-full button-shadow" style="height: 38px">' +
              '<span class="block w-[18px] h-[18px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
              '<span class="ml-2 inline">' +
                '{{ data.content }}' +
              '</span>' +
            '</button>',
      floatIndex: 5,
      onClick: this.callbacks.onMobileShowBuildingClick,
    })
  }

  private createCloseButton(): ymaps.control.Button {
    return ButtonService.createButton({
      image: closeIcon,
      content: this.i18n.t('map.actions.showGrid'),
      layout: '<button class="flex items-center text-primary font-semibold bg-white rounded-full button-shadow" style="height: 38px; width: 38px; justify-content: center;">' +
            '<span class="block w-[25px] h-[25px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
            '</button>',
      float: 'right',
      floatIndex: 200,
      onClick: this.callbacks.onCloseClick,
    })
  }

  private createFilterButton(): ymaps.control.Button {
    return ButtonService.createButton({
      image: filterIcon,
      layout: '<button class="flex items-center text-primary font-semibold bg-white rounded-full text-[1rem] button-shadow" style="height: 38px; width: 38px; justify-content: center;">' +
            '<span class="block w-[20px] h-[20px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
            '</button>',
      floatIndex: 10,
      onClick: this.callbacks.onFilterClick,
    })
  }

  public displayPointsOnMap(featureCollection: ymaps.IFeatureCollection<MapBuildingFeatureObject>): void {
    this.featureDrawerService?.drawFeatures(featureCollection)
  }

  private createGeometriesCoordinatesFromBBox(bBox: ymaps.CoordinateBounds): Array<GeometriesCoordinates> {
    const geometryCoordinates = [[[
      [bBox[0][0], bBox[0][1]],
      [bBox[0][0], bBox[1][1]],
      [bBox[1][0], bBox[1][1]],
      [bBox[1][0], bBox[0][1]],
      [bBox[0][0], bBox[0][1]],
    ]]]
    return [{ geometryCoordinates: { type: 'MultiPolygon', coordinates: geometryCoordinates } }]
  }

  private atPaintingEnd(geometriesCoordinates: Array<GeometriesCoordinates>): void {
    this.setPaintedAreasGeometriesCoordinates(geometriesCoordinates)

    this.sendAllRequests()
  }

  private setPaintedAreasGeometriesCoordinates(geometriesCoordinates: Array<GeometriesCoordinates>): void {
    const filter = ObjectHelper.copy(this.marketEntitiesStore.filter)
    filter.buildingFilter.geometriesCoordinates = geometriesCoordinates
    this.marketEntitiesStore.setFilter(filter)
  }

  public deleteAllPaintedAreas(): void {
    this.mapPaint?.deleteAllPolygons()
    this.sendAllRequests()
  }

  private createFilterForRequest(withOutBBoxCoordinates = false) {
    const transformedFilter = FilterService.transformFilterForRequest(this.marketEntitiesStore.filter)
    const isGeometriesCoordinatesInFilter = transformedFilter.buildingFilter.geometriesCoordinates?.length > 0
    if (isGeometriesCoordinatesInFilter || withOutBBoxCoordinates) {
      return transformedFilter
    }

    const geometriesCoordinates = this.createGeometriesCoordinatesFromBBox(this.getBBox())
    return {
      ...transformedFilter,
      buildingFilter: {
        ...transformedFilter.buildingFilter,
        geometriesCoordinates,
      },
    }
  }

  private changeControlsPosition() {
    const zoomControlObj = this.map.controls.get('zoomControl')
    zoomControlObj && zoomControlObj.options.set('position', { top: '160px', right: '10px' })
    const trafficControlObj = this.map.controls.get('trafficControl')
    trafficControlObj && trafficControlObj.options.set('position', { top: '70px', right: '10px' })
    const typeSelectorControlObj = this.map.controls.get('typeSelector')
    typeSelectorControlObj && typeSelectorControlObj.options.set('position', { top: '110px', right: '10px' })
  }
}
